import React, { useCallback } from 'react'
import { Dialog } from '@headlessui/react'
import { HiX } from 'react-icons/hi'
import { useRouter } from 'next/router'

import Drawer from '@/components/Drawer'
import { localizePrice } from '@/features/products/utils'

import { useCart } from '../../hooks'
import CouponCodeForm from '../CouponCodeForm'
import FreeShippingMessageBox from '../FreeShippingMessageBox'
import CouponCodePill from '../CouponCodePill'
import FreeGiftClaimer from '../FreeGiftClaimer'
import CartItem from './CartItem'

interface IProps {
  open?: boolean
  onClose: () => void
}

const FREE_GIFT_MINIMUM = 10
const FREE_GIFT_DISCOUNT_ID = 31

const CartSlider: React.FC<IProps> = ({ open, onClose }) => {
  const router = useRouter()
  /*
  TODO: Remove when #34 is done
  const { price: subTotalPrice } = usePrice({
    amount: Number(cart?.subtotalPrice),
    currencyCode: cart?.currency.code || 'USD',
  })
  const { price: totalPrice } = usePrice({
    amount: Number(cart?.totalPrice),
    currencyCode: cart?.currency.code || 'USD',
  })
  */

  const { cart, isLoading, isError } = useCart()

  // TODO: Move these serverside?
  const hasFreeGift = cart?.line_items?.physical_items?.some((item: any) =>
    item.discounts.some(
      (discount: any) => discount.id === FREE_GIFT_DISCOUNT_ID
    )
  )
  const isCartOverFreeGiftMinimum = cart?.base_amount >= FREE_GIFT_MINIMUM

  const handleCheckoutButton = useCallback(() => {
    // TODO: Get redirect URLS and/or valid cart item status
    window.location.href = '/api/checkout'
  }, [])

  return (
    <Drawer maxWidth={'md'} open={open} onClose={onClose}>
      <div
        className={'py-2 px-4 xs:py-4 sm:px-6 sm:py-6 border-b border-gray-200'}
      >
        <div className="flex items-center justify-between">
          <Dialog.Title className="text-sm xs:text-base sm:text-lg font-medium text-gray-900">
            Shopping cart
          </Dialog.Title>
          <div className="flex items-center ml-3 h-7">
            <button
              type="button"
              className="p-2 -m-2 text-gray-400 hover:text-gray-500"
              onClick={onClose}
            >
              <span className="sr-only">Close panel</span>
              <HiX className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      {cart?.line_items?.physical_items &&
      cart.line_items.physical_items.length > 0 ? (
        <>
          <div className="px-4 py-4 xs:py-6 overflow-y-auto sm:px-6">
            <div className={'flow-root'}>
              <ul role={'list'} className={'-my-6 divide-y divide-gray-200'}>
                {cart.line_items.physical_items.map(
                  // TODO: Support gift cards and digital items
                  (item: any /* TODO: Type */) => (
                    <li key={item.id} className={'py-6'}>
                      <CartItem item={item} coupons={cart.coupons} />
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div className="px-4 py-4 xs:py-6 border-t border-gray-200 sm:px-6">
            <div className="flex justify-between text-sm xs:text-base font-medium text-gray-900">
              <p>Subtotal</p>
              {cart.cart_amount ? (
                <p>{localizePrice(cart.cart_amount)}</p>
              ) : (
                '---'
              )}
            </div>
            <p className="mt-0.5 text-xs xs:text-sm text-gray-500">
              Shipping and taxes calculated at checkout.
            </p>
            <div className="my-4">
              {cart.coupons.length > 0 ? (
                <CouponCodePill code={cart.coupons[0].code} />
              ) : (
                <CouponCodeForm />
              )}
            </div>

            <div>
              <FreeShippingMessageBox cartAmount={cart.cart_amount} />
            </div>

            {/*isCartOverFreeGiftMinimum && !hasFreeGift && (
              <div>
                <FreeGiftClaimer />
              </div>
            )*/}

            <div className="mt-6">
              <button
                className="flex items-center justify-center button button--primary w-full text-sm xl:text-base"
                onClick={handleCheckoutButton}
              >
                Checkout
              </button>
            </div>
            <div className="flex justify-center mt-2 xs:mt-4 sm:mt-6 text-sm text-center text-gray-500">
              <p>
                or{' '}
                <button
                  type="button"
                  className="font-medium text-gray-900 hover:text-gray-800"
                  onClick={onClose}
                >
                  Continue Shopping<span aria-hidden="true"> &rarr;</span>
                </button>
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className={'px-4 py-6 overflow-y-auto sm:px-6'}>
          Your cart is empty.
        </div>
      )}
    </Drawer>
  )
}

export default CartSlider
