import Link from 'next/link'
import React, { useMemo } from 'react'

interface IProps {}

const NavLinkSection: React.FC<IProps> = () => {
  const navLinks = useMemo(
    () => [
      // { label: 'shop by graphic', path: '/graphics' },
      {
        label: 'categories',
        path: '/categories',
      },
      { label: 'sale shit', path: '/categories/sale-items' },
      { label: 'help center', path: '/help' },

      {
        label: 'account',
        path: '/account',
      },
      { label: 'faqs', path: '/faqs' },
    ],
    []
  )
  return (
    <div className={'flex flex-col justify-between space-y-1'}>
      {navLinks.map((navLink, index) => (
        <Link href={navLink.path} key={index} prefetch={false}>
          <a
            className={
              'text-white text-3xl font-black uppercase tracking-tight text-center'
            }
          >
            {navLink.label}
          </a>
        </Link>
      ))}
    </div>
  )
}

export default NavLinkSection
