import { createContext } from 'react'

// TODO: Move story player stuff into here from _app.tsx
export interface IStorefrontSettings {
  promotionBannerText: {
    header: string
    productTile: string
  }
}

const StorefrontSettingsContext = createContext<IStorefrontSettings>({
  promotionBannerText: {
    /* header:
      'GET FREE USA SHIPPING ON ALL ORDERS OVER {{ freeShippingMinimum }}',
      */
    header: 'OVER 15,000 NEW ITEMS!',
    productTile:
      'FREE USA SHIPPING ON ALL ORDERS OVER {{ freeShippingMinimum }}',
  },
})

StorefrontSettingsContext.displayName = 'StorefrontSettingsContext'

export default StorefrontSettingsContext
