import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { RiCoupon2Fill } from 'react-icons/ri'
import { useAddCoupon } from '../../hooks'

interface IProps {}

const CouponCodeForm: React.FC<IProps> = () => {
  const { addCoupon } = useAddCoupon()

  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm()

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        return await addCoupon(data.code)
      } catch (e: any) {
        setError('code', { message: e.response.data.error ?? 'Unknown error' })
      }
    },
    [addCoupon, setError]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <RiCoupon2Fill
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            disabled={isSubmitting}
            {...register('code', { required: true })}
            className="focus:ring-neutral-500 focus:border-neutral-500 block w-full rounded-none rounded-l-md pl-10 text-xs xs:text-sm border-gray-300"
            placeholder="Coupon code"
          />
        </div>

        <button
          disabled={isSubmitting}
          className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-xs xs:text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-neutral-500 focus:border-neutral-500"
        >
          <span>Apply</span>
        </button>
      </div>
      <div className={'text-primary text-xs mt-0.5'}>
        {errors && errors.code && errors.code.message?.toString()}
      </div>
    </form>
  )
}

export default CouponCodeForm
