import { FC } from 'react'

import { useRouter } from 'next/router'
import { FiSearch } from 'react-icons/fi'
import { useForm } from 'react-hook-form'

import { useStorefrontSettings } from '@/hooks'

interface SearchBarProps {}

const SearchBar: FC<SearchBarProps> = () => {
  const router = useRouter()
  const { promotionBannerText } = useStorefrontSettings()
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const onSubmit = (data: any) => {
    router.push({
      pathname: '/search',
      query: { q: data.q },
    })
  }

  return (
    <div className={'h-16 bg-black relative shadow-3xl'}>
      <div className="flex justify-center px-2 mx-auto max-w-xl xl:max-w-3xl container">
        <div
          className={
            'bg-primary uppercase font-extrabold text-white -top-2 absolute px-4 whitespace-nowrap max-w-md overflow-x-hidden text-xs xs:text-sm xl:text-lg xl:max-w-lg xl:-top-4'
          }
        >
          {promotionBannerText.header}
        </div>
      </div>
      <div className="flex justify-center items-center h-full max-w-lg px-6 mx-auto pt-2 sm:pt-2.5">
        {/* Added flex container with full height */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-sm sm:max-w-md md:max-w-xl xl:max-w-3xl"
        >
          <div className={'relative'}>
            <input
              disabled={isSubmitting}
              {...register('q', { required: true })}
              placeholder={'LOOKING FOR SOMETHING, STUPID?'}
              className={
                'input rounded-full py-2 px-6 placeholder:font-thin w-full text-xs sm:text-sm'
              }
            />
            <button
              className={
                'absolute inset-y-0 right-0 cursor-pointer pr-2 flex items-center text-sm'
              }
            >
              <FiSearch className={'text-lg'} />
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SearchBar
