import React, { useCallback, useEffect, useRef } from 'react'

import { useRouter } from 'next/router'
import { CgClose } from 'react-icons/cg'
import { Dialog } from '@headlessui/react'

import AppStoreBadges from './AppStoreBadges'
import SearchForm from './SearchForm'
import InstagramTiles from './InstagramTiles'
import NavLinkSection from './NavLinkSection'
// import ThirdPartyWidgets from './ThirdPartyWidgets'

interface IProps {
  open: boolean
  onClose: () => void
}

const MainMenuDialog: React.FC<IProps> = ({ open, onClose }) => {
  const router = useRouter()
  const closeButtonRef = useRef(null)

  useEffect(() => {
    router.prefetch('/search')
  }, [router])

  const handleSearchSubmit = useCallback(
    (data: any) => {
      router.push({
        pathname: '/search',
        query: { q: data.q },
      })
    },
    [router]
  )

  return (
    <Dialog
      open={open}
      initialFocus={closeButtonRef}
      onClose={onClose}
      className={'relative z-30'}
    >
      <div className={'fixed inset-0 bg-black/95'} aria-hidden />

      <div className={'fixed right-2 top-4 z-[31]'}>
        <button
          ref={closeButtonRef}
          onClick={onClose}
          className={'text-primary font-black cursor-pointer'}
        >
          <CgClose className={'text-3xl sm:text-6xl'} />
        </button>
      </div>

      <div className={'fixed inset-0 flex items-center justify-center'}>
        <div
          className={
            'flex flex-col items-center justify-center h-full w-full space-y-5'
          }
        >
          <section id={'app-store-badges'}>
            <AppStoreBadges />
          </section>

          <section
            id={'search-and-social-media'}
            className={'flex flex-col space-y-4 w-11/12 items-center'}
          >
            <div className={'w-full max-w-xs'}>
              <SearchForm onSubmit={handleSearchSubmit} />
            </div>
            <InstagramTiles />
          </section>

          <section id={'nav-links'}>
            <NavLinkSection />
          </section>

          {/* <section id={'third-party-widgets'}>
            <ThirdPartyWidgets />
          </section> */}
        </div>
      </div>
    </Dialog>
  )
}

export default MainMenuDialog
