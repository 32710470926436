import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _, { debounce, remove } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import classNames from 'classnames'
import type { LineItem } from '@commerce/types/cart'
// import usePrice from '@framework/product/use-price'
import { useRemoveItem, useUpdateItem } from '@/features/cart/hooks'
import { localizePrice } from '@/features/products/utils'
import { HiMinus, HiPlus } from 'react-icons/hi'

interface IProps {
  item: any
  coupons: any[]
}

// TODO: Support gift certificate
// (see legacy-stencil-cornerstone/assets/js/theme/global/cart-drawer.js)
const CartItem: React.FC<IProps> = ({ item, coupons }) => {
  /*
  const { price } = usePrice({
    amount: item.variant.price * item.quantity,
    baseAmount: item.variant.listPrice * item.quantity,
    currencyCode,
  })
  */

  const itemUrl = useMemo(() => {
    return `${item.url.split('.com')[1]}`
  }, [item])

  const removeItem = useRemoveItem()
  const updateItem = useUpdateItem()
  const handleQuantityChange = useCallback(
    (quantity: number) => {
      // TODO: Debounce the update
      return quantity > 0
        ? updateItem({
            item_id: item.id,
            line_item: {
              product_id: item.product_id,
              variant_id: item.variant_id,
              quantity,
            },
          })
        : removeItem(item.id)
    },
    [item, updateItem, removeItem]
  )

  // Updated logic to correctly select the "size" option
  const selectedOption = useMemo(() => {
    if (item.options?.length > 1) {
      return item.options[1]?.value // If two options, select the second (assumed to be size)
    }
    return item.options?.[0]?.value // If one option, select it
  }, [item])

  const getDiscountName = useCallback(
    (discount: any) => {
      if (
        discount.id.toLowerCase() === 'coupon' &&
        coupons &&
        coupons.length > 0
      ) {
        return coupons[0].code
      }

      return discount.id
    },
    [coupons]
  )

  return (
    <div className={'flex flex-wrap'}>
      {item.image_url && (
        <div className="shrink-0 overflow-hidden border border-gray-200 rounded-md">
          <Link href={itemUrl} prefetch={false}>
            <a>
              <div className={'relative w-16 h-full aspect-w-3 aspect-h-4'}>
                <Image
                  layout={'fill'}
                  // TODO: Lower resolution
                  src={item.image_url}
                  unoptimized
                  alt={item.name}
                  className="object-cover object-center w-full h-full"
                />
              </div>
            </a>
          </Link>
        </div>
      )}
      <div className="flex flex-col flex-1 ml-4">
        <div>
          <div className="flex justify-between text-sm xs:text-base font-medium text-gray-900">
            <h3>
              <Link href={itemUrl} prefetch={false}>
                {item.name}
              </Link>
            </h3>
            <div className={'ml-4 text-right'}>
              <p
                className={classNames({
                  'line-through text-sm': item.coupon_amount,
                })}
              >
                {localizePrice(item.sale_price)}
              </p>
              {item.coupon_amount > 0 && (
                <div>
                  <p>
                    {localizePrice(
                      item.sale_price - item.coupon_amount / item.quantity
                    )}
                  </p>
                  <div className={'space-y-0.5'}>
                    {item.discounts.map((discount: any, index: number) => (
                      <div
                        key={index}
                        className={
                          'text-primary text-xxs xs:text-xs font-light'
                        }
                      >
                        <div className={'text-ellipsis'}>
                          (-{localizePrice(discount.discounted_amount)})
                        </div>
                        <div>{getDiscountName(discount)}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {selectedOption && (
            <p className="mt-1 text-sm text-gray-500">{selectedOption}</p>
          )}
        </div>
        <div className="flex items-end justify-between flex-1 text-xs xs:text-sm">
          <div className={'flex space-x-2'}>
            <button
              disabled={!item.is_mutable}
              onClick={() => handleQuantityChange(item.quantity - 1)}
              className={
                'hover:bg-neutral-100 rounded transition-colors px-2 disabled:opacity-50'
              }
            >
              <HiMinus />
            </button>
            <input
              // TODO: Debounce and handle change
              type={'text'}
              className={
                'border-0 appearance-none w-8 text-center outline-0 disabled:opacity-50 p-0'
              }
              value={item.quantity}
              onChange={({ currentTarget: { value } }) => {
                handleQuantityChange(Number(value))
              }}
              disabled={!item.is_mutable}
            />
            <button
              disabled={!item.is_mutable}
              onClick={() => handleQuantityChange(item.quantity + 1)}
              className={
                'hover:bg-neutral-100 rounded transition-colors px-2 disabled:opacity-50'
              }
            >
              <HiPlus />
            </button>
          </div>

          {/*item.is_mutable && (
            <div className="flex">
              <button
                type="button"
                className="font-medium text-red-600 hover:text-red-500"
                onClick={() => {
                  removeItem(item.id)
                }}
              >
                Remove
              </button>
            </div>
              )*/}
        </div>
      </div>
    </div>
  )
}

export default CartItem
